import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout to="/" link_text="coming<br></br>soon">
    <SEO title="Kate Dameron - Art Studio" />
  </Layout>
)

export default IndexPage
